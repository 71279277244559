import { GridItem } from '@entur/grid';
import { ExpandablePanel } from '@entur/expand';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import { useQuery } from 'react-query';
import { getConnectors } from '../../api/API';
import { useAuth0 } from '../../auth';

type ConnectorsOverviewProps = {
    cluster: string;
    topicName: string;
};

export const ConnectorsOverview = ({ cluster, topicName }: ConnectorsOverviewProps) => {
    const auth = useAuth0();
    const connectors = useQuery(['connectors', cluster, topicName], () => getConnectors(auth, cluster, topicName));
    const getTaskCounts = (tasks: Components.Schemas.TaskStatus[] = []) => {
        const runningTasks = tasks.filter((task) => task.state === 'RUNNING').length;
        const totalTasks = tasks.length;
        return `${runningTasks}/${totalTasks}`;
    };

    return (
        <GridItem small={12}>
            <ExpandablePanel title="Connectors">
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Connector</HeaderCell>
                            <HeaderCell>Running tasks</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* TODO: vurdér å gjøre mindre hacky */}
                        {connectors.data && connectors.data.length > 0
                            ? connectors.data.map((connectorResponse) => (
                                  <TableRow key={'connector-row' + connectorResponse.name}>
                                      <DataCell>{connectorResponse.name}</DataCell>
                                      <DataCell>{getTaskCounts(connectorResponse.status?.tasks)}</DataCell>
                                  </TableRow>
                              ))
                            : ''}
                    </TableBody>
                </Table>
            </ExpandablePanel>
        </GridItem>
    );
};
